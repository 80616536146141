import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

const initialState = {
  account: {
    id: '',
    name: '',
  },
  statuses: {
    isAccountReady: false,
  },
};

export const projectUserGroupManager = proxy(initialState);

devtools(projectUserGroupManager, {
  name: 'Current User Group',
  enabled: false,
});

export const resetProjectUserGroup = () => {
  Object.assign(projectUserGroupManager, initialState);
};

export const saveProjectUserGroupAccount = (isAccountReady: boolean) => {
  projectUserGroupManager.statuses.isAccountReady = isAccountReady;
};
